import React from "react";

import SecondaryLanding from "../components/layouts/secondary-landing";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import { Link } from "gatsby";

const TermsOfUse = () => {
  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Terms of Use"
    }
  ];

  const SEOData = {
    title: "Terms of Use",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Terms of Use"
      },
      {
        name: "description",
        content: "See the terms of use for WaFd Bank personal loans, checking accounts and commercial banking"
      },
      {
        property: "og:title",
        content: "Terms of Use"
      },
      {
        property: "og:description",
        content: "See the terms of use for WaFd Bank personal loans, checking accounts and commercial banking"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/terms-of-use"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Terms and Conditions</h1>

        <p>
          <em>Effective 6/11/2024</em>
        </p>

        <p>
          Welcome to WaFd Bank's website. Your use of any portion of WaFd Bank's website ("Site") is subject to the
          following terms and conditions ("Terms"). Before using the Site, please read these Terms as well as our Online
          Privacy Policy. By accessing the Site, you acknowledge your acceptance of these Terms and our Online Privacy
          Policy.
        </p>

        <h2>License Grant and Use Restrictions; Your Indemnification</h2>

        <p>
          Subject to the Terms, we grant you a nonexclusive, revocable license to copy or print an unaltered copy of
          information on this Site to document your contracts and activities with us, but not for any other purpose,
          including commercial purposes. The trademarks, trade names, and service marks ("Marks") used on this Site
          belong to WaFd Bank or to various other parties. Information on this Site is copyrighted or protected by U.S.
          and international intellectual property laws, so you may not copy, modify, publicly distribute or display,
          perform, publish, license, create derivative works from, transfer or sell any Site information, including any
          Marks (except to the extent such constitutes fair use under the federal Copyright Act).
        </p>

        <p>
          You agree not to alter any Site information and not to use (or allow others to use) this Site or any
          information obtained from it for any wrongful, unauthorized or unlawful purpose and agree to defend, indemnify
          and hold us and our agents and affiliates harmless from and against any loss, damage or expense (including
          attorneys fees) incurred because of any such use.
        </p>

        <h2>No Warranties</h2>

        <p>
          The materials on this Site are provided "as is." From time to time, this Site may include information from
          third parties and/or links to other websites operated by third parties. We make no representations or
          warranties, express or implied, regarding the Site or any third-party website to which this Site links,
          including but not limited to any information or services provided in or through our Site or third-party
          websites. (All sites, information and services are collectively referenced as the "services.") If you use
          links to third-party websites, you will leave this Site. These Terms do not apply to any other websites. With
          respect to this Site and third-party websites, WaFd Bank does not make any warranties regarding the use of the
          materials, or that the functions contained on this Site or linked third-party websites will be uninterrupted
          or free from error, that defects will be corrected, or that this Site or any third-party website are free of
          viruses or other harmful components.
        </p>

        <h2>Viruses</h2>

        <p>
          It is possible to contract a computer virus or similar "disease" by using the internet or materials downloaded
          from it. You should obtain, use and update computer virus-checking software routinely, even when you are using
          information that is not obtained from the internet. We cannot guarantee that this Site and any software or
          other information downloaded from them will be virus or problem free and we disclaim any liability (if any)
          for viruses and similar problems.
        </p>

        <h2>Limitation of Liability</h2>

        <p>
          WaFd Bank disclaims liability for any and all claims, losses, costs, expenses (including attorneys' fees), and
          damages of whatever kind or nature including without limitation general, compensatory, special, incidental,
          consequential, punitive, or exemplary damages ("Damages") based on any theory of liability, in connection with
          any use of this Site and the information contained herein, the inability to use this Site or the materials on
          it, the misappropriation of materials, or the loss of, or damage to, materials for any reason, even if we have
          been advised of the possibility of such Damages.
        </p>

        <h2>Changes</h2>

        <p>
          We reserve the right to update these Terms from time to time to comply with applicable laws and to reflect
          changes in our online services. For this reason, we encourage you to periodically review these Terms. If we
          make changes, we will revise the "Effective/Revised" date shown at the top of the Terms and post the revised
          Policy on www.wafdbank.com. The changes will become effective when we post the revised Terms. Your visit or
          use of this Site following such posting means that you accept the revised Terms.
        </p>

        <h2>Governing Law</h2>

        <p>
          These Terms are governed by the laws of the State of Washington, and any applicable federal law. For
          individual products and services offered by WaFd Bank, the applicable agreements and disclosures may specify
          that the laws of another jurisdiction govern your use of the account and/or service, and the terms of those
          disclosures and agreements will continue to apply. WaFd Bank does not represent that the materials on this
          Site are appropriate or available for use in all locations. This Site is not intended for use by children
          under the age of 15. By using this Site, you affirm that you are over the age of 15.
        </p>

        <h2>Account Authorization and Use</h2>
        <p>
          If you have an account or plan to open an account with WaFd Bank, you agree to provide WaFd Bank with current,
          complete, and accurate information about you as prompted by the applicable registration process and agree to
          regularly update this information to maintain its completeness and accuracy. You agree to use this Site to
          access only those accounts on which you are authorized to act, and to use your own usernames, logins and
          passwords when accessing accounts on which you are authorized to act. You are solely responsible for all
          activities that occur in connection with your password. Accordingly, you should take steps to protect the
          confidentiality of any account information, usernames, logins and passwords that you use to access any page or
          feature on this Site, and for logging off your account and any protected areas of the Site.
        </p>
        <p>
          If you become aware of any suspicious or unauthorized conduct concerning your accounts, usernames, logins,
          passwords, or security questions and answers, you agree to contact WaFd Bank immediately. WaFd Bank will not
          be liable for any loss or damage arising from your failure to comply with these Terms of Use.
        </p>
        <p>
          You authorize your wireless carrier to use or disclose information about your account and your wireless
          device, if available, to WaFd Bank or its service provider for the duration of your business relationship,
          solely to help them identify you or your wireless device and to prevent fraud. See our{" "}
          <Link to="/privacy-center" id="privacy-center-page-link">
            Privacy Center
          </Link>{" "}
          for how to see how we treat your data.
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default TermsOfUse;
